import '../../../../src/components/protons/icon/Icon';
import '../anchor/Anchor';
import type { DefaultComponentType } from '../../../../src/utils/types';
import type NavigationList from '../../molecules/navigation-list/NavigationList';
// eslint-disable-next-line import/no-cycle
import { NavigationListEvents } from '../../molecules/navigation-list/NavigationList';
import type Anchor from '../anchor/Anchor';
import type { NavigationListLineProps } from '../navigation-list-line/NavigationListLine';
import './NavigationListItem.pcss';

export enum NavigationListItemTypes {
    DEFAULT = 'default', // deprecated
    NEW_TAB = 'new_tab',
    ACTION = 'action',
    SUBMENU = 'submenu'
}

export type NavigationListItemProps = DefaultComponentType & {
    label: string;
    href: string;
    type?: NavigationListItemTypes;
    role?: string;
    shouldAnimate?: boolean;
    tabIndex?: number;
    children?: NavigationListItemProps[] | NavigationListLineProps[];
};

export default class NavigationListItem extends HTMLLIElement {
    readonly #anchor: Anchor | null = this.querySelector('[aria-haspopup="true"]');

    constructor() {
        super();

        this.#anchor = this.querySelector('[aria-haspopup="true"]');
    }

    public connectedCallback(): void {
        if (!this.#anchor) {
            return;
        }

        this.#anchor.addEventListener('click', this.openSubmenu.bind(this));
    }

    public disconnectedCallback(): void {
        if (!this.#anchor) {
            return;
        }

        this.#anchor.removeEventListener('click', this.openSubmenu.bind(this));
    }

    public openSubmenu(event: Event): void {
        event.preventDefault();
        this.setAttribute('aria-expanded', 'true');
        const list = this.querySelector('[is="nh-navigation-list"]') as NavigationList;
        this.#triggerListEvent(list, NavigationListEvents.OPENED, 264);

        const parentList = this.closest('[is="nh-navigation-list"]') as NavigationList;
        this.#triggerListEvent(parentList, NavigationListEvents.CLOSED, 264);
    }

    public closeSubmenu(event: Event): void {
        event.preventDefault();
        this.setAttribute('aria-expanded', 'false');

        const previousList = this.closest('[is="nh-navigation-list"]') as NavigationList;
        this.#triggerListEvent(previousList, NavigationListEvents.OPENED, 160);

        const backButton = event.currentTarget as HTMLButtonElement;
        if (!backButton) {
            return;
        }

        const currentList = backButton.closest('[is="nh-navigation-list"]') as NavigationList;
        this.#triggerListEvent(currentList, NavigationListEvents.CLOSED, 160);
    }

    #triggerListEvent(list: NavigationList, event: NavigationListEvents, timeout: number): void {
        setTimeout(() => {
            list.dispatchEvent(new Event(event));
        }, timeout);
    }
}

if (!window.customElements.get('nh-navigation-list-item')) {
    window.customElements.define('nh-navigation-list-item', NavigationListItem, { extends: 'li' });
}
