import '../../../../src/components/protons/icon/Icon';
import '../../molecules/navigation-dropdown/NavigationDropdown';
import '../../atoms/button/Button';
import '../../atoms/image/Image';
import '../menu-dialog/MenuDialog';
import type { IconProps } from '../../../../src/components/protons/icon/Icon';
import NavigationDropdown, {
    type NavigationDropdownProps
} from '../../molecules/navigation-dropdown/NavigationDropdown';
import type { AnchorProps } from '../../atoms/anchor/Anchor';
import type { ImageProps } from '../../atoms/image/Image';
import MenuDialog, { type MenuDialogProps } from '../menu-dialog/MenuDialog';
import type Button from '../../atoms/button/Button';
import './Navigation.pcss';

type MenuItemType = AnchorProps & {
    href?: string;
    menuItems?: AnchorProps[] | NavigationDropdownProps[];
};

export type NavigationProps = {
    logo: ImageProps | IconProps;
    baseUrl?: string;
    menuItems: MenuItemType[];
    menuAlignment?: 'center' | 'right';
    dropdown?: NavigationDropdownProps;
    transparent?: boolean;
    navigationList?: MenuDialogProps;
};

export default class Navigation extends HTMLElement {
    #menuDialogButton = this.querySelector('[data-role="menu-dialog-button"]') as Button;

    #menuDialog = document.querySelector('[data-role="menu-dialog"]') as MenuDialog;

    get transparent(): boolean {
        return this.hasAttribute('transparent');
    }

    set transparent(value: boolean) {
        this.toggleAttribute('transparent', value);
    }

    readonly #navigationDropdowns: NodeListOf<NavigationDropdown> =
        this.querySelectorAll('nh-navigation-dropdown');

    public connectedCallback(): void {
        this.#menuDialogButton = this.querySelector('[data-role="menu-dialog-button"]') as Button;

        this.#menuDialog = document.querySelector('[data-role="menu-dialog"]') as MenuDialog;

        this.addEventListener('openedMenu', this.#closeAllOtherMenus.bind(this));

        if (!this.#menuDialogButton || !this.#menuDialog) {
            return;
        }

        this.#menuDialogButton.addEventListener('click', this.#toggleMenuDialog.bind(this));
    }

    public disconnectedCallback(): void {
        this.removeEventListener('openedMenu', this.#closeAllOtherMenus.bind(this));

        if (!this.#menuDialogButton || !this.#menuDialog) {
            return;
        }

        this.#menuDialogButton.removeEventListener('click', this.#toggleMenuDialog.bind(this));
    }

    #closeAllOtherMenus(event: Event): void {
        this.#navigationDropdowns.forEach((dropdown) => {
            if (dropdown === event.target) {
                return;
            }
            dropdown.close();
        });
    }

    #toggleMenuDialog(): void {
        if (this.#menuDialog.element.open) {
            this.#menuDialog.close();
            this.#menuDialogButton.setAttribute('aria-expanded', 'false');
            return;
        }

        this.#menuDialogButton.setAttribute('aria-expanded', 'true');
        this.#menuDialog.show();
    }
}

if (!window.customElements.get('nh-navigation')) {
    window.customElements.define('nh-navigation', Navigation);
}
