import '../../atoms/button/Button';
import '../../atoms/dropdown-menu-item/DropdownMenuItem';
import '../../atoms/nav-option/NavOption';
import type { ButtonProps } from '../../atoms/button/Button';
import type { DropdownMenuItemProps } from '../../atoms/dropdown-menu-item/DropdownMenuItem';
import type { NavOptionProps } from '../../atoms/nav-option/NavOption';
import './NavigationDropdown.pcss';

type NavigationDropdownMenuItemProps = DropdownMenuItemProps & {
    children?: DropdownMenuItemProps[];
};

export type NavigationDropdownProps = NavOptionProps & {
    open?: boolean;
    leftAligned?: boolean;
    menuItems: NavigationDropdownMenuItemProps[];
    mobileMenuItems?: NavigationDropdownMenuItemProps[];
    button?: ButtonProps;
};

export default class NavigationDropdown extends HTMLElement {
    readonly #dropdown: HTMLElement | null = null;

    readonly #content: HTMLElement | null = null;

    #isOpen = Boolean(this.hasAttribute('open'));

    constructor() {
        super();

        this.#dropdown = this.querySelector('[data-role="dropdown"]');
        this.#content = this.querySelector('[data-role="content"]');
    }

    protected connectedCallback(): void {
        if (!this.#dropdown || !this.#content) {
            return;
        }

        this.#dropdown.addEventListener('click', this.#toggle.bind(this));
    }

    protected disconnectedCallback(): void {
        if (!this.#dropdown || !this.#content) {
            return;
        }

        this.#dropdown.removeEventListener('click', this.#toggle.bind(this));
    }

    public open(): void {
        this.setAttribute('open', 'true');
        this.#isOpen = true;
        this.dispatchEvent(new CustomEvent('openedMenu', { bubbles: true }));
    }

    public close(): void {
        this.removeAttribute('open');
        this.#isOpen = false;
        this.dispatchEvent(new CustomEvent('closedMenu', { bubbles: true }));
    }

    #toggle(event: Event): void {
        event.preventDefault();

        if (this.#isOpen) {
            this.close();
            return;
        }

        this.open();
    }
}

if (!window.customElements.get('nh-navigation-dropdown')) {
    window.customElements.define('nh-navigation-dropdown', NavigationDropdown);
}
