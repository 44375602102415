export default class StickyCTA extends HTMLElement {
    readonly #bodyClass = 'footerIsSticky';

    readonly #observer: IntersectionObserver | null = null;

    readonly #options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
    };

    constructor() {
        super();

        if (!('IntersectionObserver' in window)) {
            return;
        }

        this.#observer = new IntersectionObserver(this.intersectionObserverCallback, this.#options);
    }

    public connectedCallback(): void {
        if (!this.#observer) {
            return;
        }

        this.#observer.observe(this);
    }

    public disconnectedCallback(): void {
        if (!this.#observer) {
            return;
        }

        this.#observer.disconnect();
    }

    public intersectionObserverCallback = (entries: IntersectionObserverEntry[]): void => {
        const force = entries.filter((entry) => entry.isIntersecting).length > 0;
        document.body.classList.toggle(this.#bodyClass, !force);
    };
}

if (!customElements.get('nh-sticky-cta')) {
    customElements.define('nh-sticky-cta', StickyCTA);
}
