/* eslint-disable import/no-duplicates */
import '../../molecules/dialog/Dialog';
import '../../molecules/navigation-list/NavigationList';
import {
    NavigationListEvents,
    type NavigationListProps
} from '../../molecules/navigation-list/NavigationList';
import Dialog from '../../molecules/dialog/Dialog';
/* eslint-enable import/no-duplicates */
import './MenuDialog.pcss';

export type MenuDialogProps = NavigationListProps;

export default class MenuDialog extends Dialog {
    #list = this.querySelector('[role="menu"]');

    public show(): void {
        super.show();
        this.#list?.dispatchEvent(new Event(NavigationListEvents.OPENED));
    }

    public close(): void {
        super.close();
        this.#list?.dispatchEvent(new Event(NavigationListEvents.CLOSED));
    }
}

if (!window.customElements.get('nh-menu-dialog')) {
    window.customElements.define('nh-menu-dialog', MenuDialog);
}
