import '../../../../src/components/protons/icon/Icon';
import '../../atoms/anchor/Anchor';
import '../../atoms/navigation-list-item/NavigationListItem';
import '../../atoms/navigation-list-line/NavigationListLine';
import type { DefaultComponentType } from '../../../../src/utils/types';
import type Anchor from '../../atoms/anchor/Anchor';
import type NavigationListItem from '../../atoms/navigation-list-item/NavigationListItem';
import type { NavigationListItemProps } from '../../atoms/navigation-list-item/NavigationListItem';
import type { NavigationListLineProps } from '../../atoms/navigation-list-line/NavigationListLine';
import './NavigationList.pcss';

export type NavigationListItemsProps = NavigationListItemProps[] | NavigationListLineProps[];

export type NavigationListProps = DefaultComponentType & {
    items: NavigationListItemsProps;
    backButtonLabel?: string;
    shouldAnimate?: boolean;
};

export enum NavigationListEvents {
    OPENED = 'navigation_list_opened',
    CLOSED = 'navigation_list_closed'
}

export default class NavigationList extends HTMLUListElement {
    readonly #backButton = this.querySelector('[data-role="back"]') as Anchor;

    readonly #parentItem = this.closest('[is="nh-navigation-list-item"]') as NavigationListItem;

    readonly animatableListItems: HTMLLIElement[];

    constructor() {
        super();
        this.animatableListItems = Array.from(this.children).filter(
            (item) => item.tagName === 'LI' && item.getAttribute('data-animated') === 'false'
        ) as HTMLLIElement[];
    }

    public connectedCallback(): void {
        this.addEventListener(NavigationListEvents.OPENED, this.#handleShowListItemAnimation);
        this.addEventListener(NavigationListEvents.CLOSED, this.#handlehideListItemAnimation);

        if (!this.#backButton || !this.#parentItem) {
            return;
        }

        this.#backButton.addEventListener('click', this.#handleBackButtonClick);
    }

    public disconnectedCallback(): void {
        this.removeEventListener(NavigationListEvents.OPENED, this.#handleShowListItemAnimation);
        this.removeEventListener(NavigationListEvents.CLOSED, this.#handlehideListItemAnimation);

        if (!this.#backButton || !this.#parentItem) {
            return;
        }

        this.#backButton.removeEventListener('click', this.#handleBackButtonClick);
    }

    #handleShowListItemAnimation = (): void => {
        const animationInterval = 50;
        let delay = 0;

        for (const item of this.animatableListItems) {
            setTimeout(() => {
                item.setAttribute('data-animated', 'true');
            }, delay);

            delay += animationInterval;
        }
    };

    #handlehideListItemAnimation = (): void => {
        for (const item of this.animatableListItems) {
            item.setAttribute('data-animated', 'false');
        }
    };

    #handleBackButtonClick = (event: Event) => {
        this.#parentItem.closeSubmenu(event);
    };
}

if (!window.customElements.get('nh-navigation-list')) {
    window.customElements.define('nh-navigation-list', NavigationList, { extends: 'ul' });
}
